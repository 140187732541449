import React, { Component } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import Banner from '../../../core/Banner';
import Panel from '../../../core/Panel';
import Heading from '../../../core/Heading';
import Columns from '../../../core/Columns';
import Column from '../../../core/Column';
import Image from '../../../core/Image';
import Paragraph from '../../../core/Paragraph';

import './index.css';

import channelsImage from './images/channel_mgr_diagram 2.svg';
import controlYourRelationshipImage from './images/channel-mgr-couple.jpg';
import noWorkForStaffImage from './images/channel-mgr-front-desk.jpg';

export class ChannelManagerPage extends Component {
  static propTypes = {};

  render() {
    return (
      <App>
        <div className="component channel-manager-page">
          <Helmet title="Channel Manager for Hotels & More – ThinkReservations">
            <meta
              name="description"
              content="Get in the driver’s seat when you work with OTAs. Our robust channel manager syncs in real time — saving you time and effort. Click here to learn more."
            />
            <script
              type="application/ld+json"
              children={JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'Service',
                serviceType: 'Channel Manager Solutions',
                category:
                  'Reservation Management Software for Small and Boutique Hotels',
                provider: {
                  '@type': 'Organization',
                  name: 'ThinkReservations',
                },
                areaServed: {
                  '@type': 'Country',
                  name: 'USA',
                },
              })}
            />
          </Helmet>

          <Banner
            header="Channel Manager"
            subheader="Direct connectivity to the channels you need"
            content="Our Channel Manager is built in-house and is designed to give you more control
             over your relationships with the OTAs. Sync info the easy way with this convenient, 
             powerful hotel channel manager software. Thanks to the many features and functions built 
             into this software, managing your online presence can be much easier — 
             no matter which platforms your guests use to book with you. 
             Read on to learn about all the benefits our solution can provide."
          />

          <Panel id="direct-connect" backgroundColor="#f5f3f3">
            <Columns>
              <Column>
                <Image src={channelsImage} />
              </Column>
              <Column>
                <Heading size="large" level={2}>
                  Real-time synchronization
                </Heading>
                <Paragraph>
                  Get your hotel, motel, inn, B&B, or vacation rental on the top
                  booking channels with our hospitality channel manager.
                  ThinkReservation automatically updates availability, rates,
                  and restrictions with Expedia, Booking.com, Airbnb,
                  HotelTonight, TripAdvisor, and their affiliates instantly.
                  This eliminates double bookings, saves you significant time,
                  and helps you book more rooms!
                </Paragraph>
              </Column>
            </Columns>
          </Panel>

          <Panel id="control-your-relationship">
            <Columns>
              <Column>
                <Heading size="large" level={2}>
                  Control your relationship with OTAs
                </Heading>
                <Paragraph>
                  Decide which rooms you want to make available when you want
                  them available, at the price you want, on a per-room per-night
                  basis. Choose the OTAs you want to work with, customize your
                  rates, restrictions, and deposit policies, and stop
                  underselling your rooms. Get in the driver’s seat with your
                  channel manager bookings!
                </Paragraph>
              </Column>
              <Column>
                <Image src={controlYourRelationshipImage} />
              </Column>
            </Columns>
          </Panel>

          <Panel id="no-work" backgroundColor="#f5f3f3">
            <Columns>
              <Column>
                <Image src={noWorkForStaffImage} />
              </Column>
              <Column>
                <Heading size="large" level={2}>
                  No work for you and your staff
                </Heading>
                <Paragraph>
                  Our hotel channel manager will synchronize all your
                  reservations and availability across all the channels to help
                  you prevent double bookings and save time. With our Channel Manager being integrated 
                  into your existing ThinkReservations property management system (PMS), you gain powerful automation across 
                  many of your operations. This is especially useful for smaller hospitality businesses
                  because it enables them to do more with less. 
                  You’ll be able to offer your guests a better experience and increase bookings at the same 
                  time.
                </Paragraph>
              </Column>
            </Columns>
          </Panel>
        </div>
      </App>
    );
  }
}

export default ChannelManagerPage;
